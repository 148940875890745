import axios, { AxiosResponse } from 'axios';
import User from '../models/User';
import router from '@/router';
import { openOAuth } from '@/utils/openOauth';
import config from '@/config';

export default {
  namespaced: true,
  state() {
    return {
      /**
       * Объект авторизованного пользователя
       */
      user: new User(),

      /**
       * Объект авторизованного пользователя из lk
       */
      userLk: {},

      /**
       * Текущий Access token
       */
      accessToken: '',

      /**
       * Текущие запросы
       */
      promises: {},

      /**
       * Контроллер для отмены запросов
       */
      abortController: new AbortController(),

      /**
       * Список запрошенных доступов
       */
      requestedAccesses: [],

      /**
       * Таймер для завершения сессии пользователя
       */
      logoutTimer: null,

      /**
       * Таймер для отображения плашки с предупреждением о скором окончании сессии
       */
      logoutModalTimer: null,

      /**
       * Флаг отображения плашки с предупреждением о скором окончании сессии
       */
      logoutModalShow: false,
    };
  },
  mutations: {
    /**
     * Заполнение данных пользователя
     *
     * @param state
     * @param data Объект данных, полученный от API
     */
    setUserData(state: Record<string, any>, data: Record<string, any>): void {
      state.user.id = data.user.id;
      state.user.login = data.user.login;
      state.user.email = data.user.email;
      state.user.gln = data.user.gln;
      state.user.phone = data.user.phone;
      state.user.name = data.user.name;
      state.user.role = data.user.role;
      state.user.pro = data.user.pro == 1;
      state.user.tokens = data.user.tokens_by_api;
      state.user.declineReasons = data.user.decline_reasons_by_api;
      state.user.loggedIn = data.loggedIn;
      state.user.membershipStatus = data.user.active_membership;
      state.user.membershipActiveTill = data.user.membership_active_till;
      state.user.companyName = data.user.company ? data.user.company.name : '';
      state.user.companyInn = data.user.company ? data.user.company.inn : '';
      state.user.companyGln = data.user.company ? data.user.company.gln : '';
      state.user.fullName = typeof data.user.full_name !== 'undefined' ? data.user.full_name : '';
    },

    setPromise(state: Record<string, any>, data: Record<string, any>): void {
      state.promises[data.promiseName] = data.promise;
    },

    setUserLk(state: Record<string, any>, data: Record<string, any>): void {
      state.userLk = data;
    },

    setAccessToken(state: Record<string, any>, token: string): void {
      state.accessToken = token;
    },

    /**
     * Отмечает доступ пользователя
     *
     * @param state
     * @param policyName Название доступа
     */
    setAccess(state: Record<string, any>, data: Record<string, any>): void {
      data.policyNames.forEach((policyName: string) => {
        if (!state.user.access[policyName]) {
          state.user.access[policyName] = data.access[policyName];
        }
      });
    },

    /**
     * Сброс всех доступов пользователя
     *
     * @param state
     */
    clearAccess(state: Record<string, any>): void {
      for (const access in state.user.access) {
        delete state.user.access[access];
      }
    },

    /**
     * Сброс контроллера для отмены запросов
     *
     * @param state
     */
    resetAbortController(state: Record<string, any>): void {
      state.abortController = new AbortController();
    },

    /**
     * Добавление доступа в список запрошенных доступов
     *
     * @param state
     * @param policy Название доступа
     */
    addRequestedPolicy(state: Record<string, any>, policy: string): void {
      if (!state.requestedAccesses.includes(policy)) {
        state.requestedAccesses.push(policy);
      }
    },

    /**
     * Скрыть предпреждение о сессии
     *
     * @param state
     */
    hideLogoutModal(state: Record<string, any>): void {
      state.logoutModalShow = false;
    },

    /**
     * Показать предпреждение о сессии
     *
     * @param state
     */
    showLogoutModal(state: Record<string, any>): void {
      state.logoutModalShow = true;
    },
  },
  actions: {
    /**
     * Попытка авторизации
     *
     * @param context
     * @param data Данные для авторизации. Логин (login) и пароль (password)
     */
    async loginAttempt({ commit }: { commit: any }, data: Record<string, string>): Promise<boolean> {
      commit('setLoading', { flagName: 'login', flagState: true }, { root: true });

      try {
        await axios.post('auth/login', data);

        return true;
      } catch (errors) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'login', flagState: false }, { root: true });
      }
    },

    async getAccessToken({ commit }: { commit: any; state: any }): Promise<any> {
      return await axios.get('auth/access-token').then((r: any) => {
        commit('setAccessToken', r?.data?.access_token);
        return r?.data?.access_token;
      });
    },

    /**
     * Получение данных пользователя
     *
     * @param context
     */
    async getUser({ state, commit, dispatch }: { state: any; dispatch: any; commit: any }, force = false): Promise<any> {
      const errorStatuses = [401];
      try {
        if (state.user.id && !force) return true;
        if (state.promises.user && !force) {
          return state.promises.user;
        }
        const promise = axios
          .get('auth/user', {
            signal: state.abortController.signal,
          })
          .then((response) => {
            commit('setUserData', { user: response.data, loggedIn: true });
            commit('setPromise', { promiseName: 'user', promise: false });
            dispatch('refreshToken', true).then(() => dispatch('getAccessToken').then((r: any) => dispatch('getUserFromLk', r)));

            return true;
          })
          .catch((error) => {
            if (errorStatuses.includes(error?.response?.status)) {
              return openOAuth();
            }
            return false;
          });

        commit('setPromise', { promiseName: 'user', promise: promise });
      } catch (errors) {
        commit('setUserData', { user: new User(), loggedIn: false });
        return false;
      }
    },

    /**
     * Получение данных пользователя из LK
     *
     * @param context
     */
    async getUserFromLk({ commit }: { state: any; dispatch: any; commit: any }, lkToken: string): Promise<any> {
      try {
        return axios
          .get(`${config.lkEndpoint}/user/info`, {
            headers: {
              Authorization: `Bearer ${lkToken}`,
            },
          })
          .then((response) => {
            commit('setUserLk', response.data);

            return true;
          })
          .catch((error) => {
            return false;
          });
      } catch (errors) {
        return false;
      }
    },

    /**
     * Проверка доступа пользователя
     *
     * @param context
     * @param policyNames Название доступов, которые нужно проверить
     */
    async checkAccess({ state, commit }: { state: any; commit: any }, policyNames: Array<string>): Promise<any> {
      try {
        commit('setLoading', { flagName: 'check-access', flagState: true }, { root: true });

        let result = true;
        let returnResult = true;
        const policyNameString = policyNames.join(';');

        policyNames.forEach((policy: string) => {
          commit('addRequestedPolicy', policy);
        });

        // Если уже был получен результат проверки доступа, то возвращается данный результат
        policyNames.forEach((policyNameString) => {
          if (typeof state.user.access[policyNameString] === 'undefined') {
            returnResult = false;
          } else {
            if (!state.user.access[policyNameString]) {
              result = false;
            }
          }
        });
        if (returnResult) return result;

        // Если уже где-то был сделан запрос проверки доступа, но ещё ожидается ответ, то возвращается promise этого запроса
        if (state.promises[policyNameString]) {
          return state.promises[policyNameString];
        }

        // Создание запроса проверки доступа
        const promise = axios
          .post(
            'auth/access',
            {
              policies: policyNames,
              params: router.currentRoute.value.params,
            },
            {
              signal: state.abortController.signal,
            }
          )
          .then((response: Record<string, any>) => {
            commit('setAccess', { policyNames: policyNames, access: response.data.access });

            result = true;

            policyNames.forEach((policyName) => {
              if (!response.data.access[policyName]) {
                result = false;
              }
            });

            return result;
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              return;
            }
          })
          .finally(() => commit('setLoading', { flagName: 'check-access', flagState: false }, { root: true }));

        // Сохранение promise запроса доступа
        commit('setPromise', { promiseName: policyNameString, promise: promise });

        return promise;
      } catch (errors) {
        return false;
      }
    },

    /**
     * Выход из системы
     */
    async logout({ commit }: { commit: any }): Promise<any> {
      try {
        await axios.get('auth/logout').then(() => {
          commit('clearAccess');
          commit('setUserData', { user: new User(), loggedIn: false });
        });
        return true;
      } catch (errors) {
        return false;
      }
    },

    /**
     * Авторизация через OAuth
     * @param context
     * @param code Код для запроса токена
     */
    async oauth(_context: any, code: string): Promise<any> {
      try {
        const response = await axios.post<any, AxiosResponse<{ success: boolean; redirect: string }>>(
          'auth/oauth',
          {
            code: code,
            redirectUri: location.protocol + '//' + location.host + config.oauthRedirectUrl,
          },
          {
            timeout: 30000,
          }
        );

        if ('success' in response.data && !response?.data?.success) {
          if (typeof response.data.redirect !== 'undefined') {
            location.href = response.data.redirect;
          }

          return false;
        }

        return true;
      } catch (errors) {
        return false;
      }
    },

    /**
     * Проверка подтверждения данных пользователя
     *
     * @param context
     * @param data
     */
    async checkUserData({ commit }: { commit: any }, data: Record<string, any>): Promise<Record<string, string>> {
      commit('setLoading', { flagName: 'auth', flagState: true }, { root: true });

      try {
        const response: Record<string, any> = await axios.post('users/check-data/' + data.type, {
          value: data.value,
          code: data.code,
        });
        const result = response.data.result;

        return result;
      } catch (errors) {
        return { status: 'error' };
      } finally {
        commit('setLoading', { flagName: 'auth', flagState: false }, { root: true });
      }
    },

    /**
     * Обновляет все уже полученные доступы пользователя
     *
     * @param context
     */
    async refreshAccess({ dispatch, state, commit }: { dispatch: Function; state: any; commit: Function }): Promise<void> {
      commit('clearAccess');

      return dispatch('checkAccess', state.requestedAccesses);
    },

    /**
     * Отмена запросов
     *
     * @param context
     */
    async cancelRequests({ state, commit }: { state: any; commit: Function }): Promise<void> {
      state.abortController.abort();
      commit('resetAbortController');
    },

    /**
     * Обновление access токена
     *
     * @param context
     * @param force Пропустить проверку времени последнего запроса
     */
    async refreshToken({ state, dispatch }: { state: any; dispatch: Function }, force = false): Promise<any> {
      if (state.logoutModalShow || !state.user.loggedIn) return;

      try {
        if (!force) {
          // Cooldown на 1 минуту
          const currentTimestamp = Math.floor(Date.now() / 1000);
          if (localStorage.getItem('last_token_refresh') === null || +localStorage.getItem('last_token_refresh')! < currentTimestamp - 60) {
            localStorage.setItem('last_token_refresh', '' + currentTimestamp);
          } else {
            return;
          }
        }

        return axios
          .post<any, AxiosResponse<{ success: boolean; redirect: string; expires_in: number }>>('auth/refresh', {
            redirectUri: location.protocol + '//' + location.host + config.oauthRedirectUrl,
          })
          .then((response: Record<string, any>) => {
            if (typeof response?.data == 'undefined') return;

            if ('expires_in' in response?.data) {
              dispatch('setLogoutTimer', response?.data?.expires_in);
            }

            return true;
          })
          .catch(() => {
            return;
          });
      } catch (errors) {
        return false;
      }
    },

    /**
     * Установка и обработка таймера сессии
     *
     * @param context
     * @param secondsLeft Кол-во секунд до завершения сессии
     */
    setLogoutTimer({ dispatch, commit, state }: { dispatch: Function; commit: Function; state: any }, secondsLeft: number): void {
      if (state.logoutTimer !== null) {
        clearTimeout(state.logoutTimer);
      }
      if (state.logoutModalTimer !== null) {
        clearTimeout(state.logoutModalTimer);
      }

      if (secondsLeft > 0) {
        // Если осталось меньше 40 минут сессии, то модальдное окно всплывет через 80% от оставшегося времени.
        // Если осталось более 40 минут, то модальное окно появится за 20 минут до окончания сессии.
        const secondsToLogoutModal = secondsLeft > 2400 ? secondsLeft - 1200 : secondsLeft * 0.8;

        state.logoutModalTimer = setTimeout(async () => {
          commit('showLogoutModal');
        }, secondsToLogoutModal * 1000);
      }

      state.logoutTimer = setTimeout(async () => {
        await axios.get<any, AxiosResponse<{ expired: boolean; expires_time: number }>>('auth/session').then((response: Record<string, any>) => {
          if (typeof response.data.expired == 'undefined' || response.data.expired) {
            openOAuth(true);
          } else {
            dispatch('refreshToken');
          }
          return true;
        });
      }, secondsLeft * 1000);
    },
  },
  modules: {},
};
